<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <button class="btn btn-success btn-sm" @click="editFeedback()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="feedbacks"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("form.ar_feedback") }}</th>
          <th>{{ $t("form.en_feedback") }}</th>
          <th>{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.name_ar }}
          </td>
          <td class="budget">
            {{ row.item.name_en }}
          </td>
          <td class="budget text-right">
            <button
              class="btn btn-success btn-sm"
              @click="editFeedback(row.item)"
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- Modal -->
  <div
    v-if="editModal"
    class="modal fade show d-block"
    id="editModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editModalLabel">
            {{ $t("form.edit feedback type") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="editModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{
                  $t("form.en_feedback")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_en"
                />
              </div>
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{
                  $t("form.ar_feedback")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_ar"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="editModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateFeedback()"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "feedbacks-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      loading: false,
      editModal: false,
      currentData: {},
      updatedData: {},
      feedbacks: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.moment = moment;
    this.getFeedbacks("admin/administration/feedbackTypes/index?page=1");
  },
  watch: {
    page: function () {
      this.getFeedbacks(
        "admin/administration/feedbackTypes/index?page=" + this.page
      );
    },
  },
  methods: {
    getFeedbacks: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.feedbacks = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Feedback
    editFeedback(feedbackData = null) {
      this.editModal = true;
      if (feedbackData) {
        this.currentData = feedbackData;
        this.updatedData.name_en = feedbackData.name_en;
        this.updatedData.name_ar = feedbackData.name_ar;
        this.updatedData.id = feedbackData.id;
      }
    },
    // Create Or Update Feedback
    updateFeedback: function () {
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.id) {
        url = "admin/administration/feedbackTypes/update";
      } else {
        url = "admin/administration/feedbackTypes/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
              });
            } else {
              this.feedbacks.filter((item) => {
                if (this.updatedData.id === item.id) {
                  item.name_en = this.updatedData.name_en;
                  item.name_ar = this.updatedData.name_ar;
                }
              });
              this.updatedData = {};
              this.editModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.updatedData["id"]) {
        fd.append("_method", "put");
        fd.append("id", this.updatedData["id"]);
      }
      fd.append("ar[name]", this.updatedData["name_ar"]);
      fd.append("en[name]", this.updatedData["name_en"]);
      return fd;
    },
  },
};
</script>
